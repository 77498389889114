@import url(https://fonts.googleapis.com/css?family=Nunito&display=swap);
body {
  margin: 0;
  font-family: "Nunito", "Muli", sans-serif;
}

.banner-image {
  /* background-image: url("/venningtonbanner.png"); */
  background-image: linear-gradient(
      0deg,
      rgba(29, 15, 23, 0.3),
      rgba(14, 5, 10, 0.3)
    ),
    url("https://res.cloudinary.com/relive-cloud/image/upload/v1591695975/vennington/lobby-area.jpg");
  background-repeat: no-repeat;
  margin: 0px;
  padding: 0px;
  height: 80vh;
  width: 100%;
  background-size: cover;
  background-position: center center;
}

.bg-orange {
  background-color: #f89620;
}
.rounded {
  border-radius: 30px !important;
}

.roomimgwidth {
  width: 100%;
  height: 70%;
}

.bg-grey {
  background: #e3e3e3;
  border-radius: 50px;
  padding: 10px;
}
.text-orange {
  color: #f89639;
}

.roomimgwidth:hover {
  box-shadow: 0px 0px 13px 9px rgba(0, 0, 0, 0.07);

  cursor: pointer;
}

.roomdetailsimg {
  width: 100%;
  height: 70vh;
  /* background-size: cover; */
  background-repeat: no-repeat;
  background-position: center;
  z-index: -999;
}
.roomdetails {
  border-top-left-radius: 40px;
  border-top-right-radius: 40px;
  margin-top: -50px;
  margin-bottom: -47.5px;
}

.de-active {
  cursor: pointer;
  padding: 10px;
  margin: 0;
}

.active {
  cursor: pointer;
  padding: 10px;
  margin: 0;
  font-size: 1.2rem;
}

@media only screen and (max-width: 500px) {
  .h6 {
    font-size: 12px !important ;
  }
  .w-55 {
    width: 100% !important;
  }
}

.btn-round {
  background: #a02b2d;
  border-radius: 50px;
  color: #fff;
}

.w-55 {
  width: 55%;
}

.aminitext {
  font-size: 14px;
}

.clubsimg {
  width: 100%;
  height: 250px;
}

.inquirybtn {
  border-radius: 50px;
}

.textwrap {
  white-space: nowrap;
}

a {
  color: inherit;
}

.facilitiesicon {
  width: 60px;
}

.bordermin {
  border: 5px solid #a3762c;
  border-radius: 50px;
  width: 10%;
}

.borderminser {
  border: 5px solid #a3762c;
  border-radius: 50px;
  width: 5%;
}

.serviceicon {
  width: 100px;
}

.facilitiespageicon {
  width: 400px;
}

.mycontent-left {
  border-left: 1px solid #e3e3e3;
}

.formw {
  width: 72% !important;
}

.text-maroon {
  color: #8f301d;
}

.roomtext {
  color: #6d3258;
}

.headtext {
  color: #a3762c !important;
}

a:hover {
  color: none;
  border-bottom: 2px solid #a3762c;
}

.text-dg {
  color: #545959;
}

.btn-outline-maroon {
  border: 1px solid #8f301d;
  color: #8f301d;
}

.btn-outline-maroon {
  background: #8f301d;
  color: #fff;
}
.text-lightgolden {
  color: #867f74;
}

.bg-golden {
  background: #a3762c;
  color: #fff;
}

.bg-cover {
  height: 80vh;
  background-repeat: no-repeat;
  background-size: cover;
}

.btn-dark {
  background: #000 !important;
}

@media (max-width: 500) {
  .mobw {
    text-align: center;
  }
  .text-right {
    display: flex !important;
    justify-content: center !important;
  }
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* .Cal__Header__date {
  font-size: 1.5rem !important;
  padding: 0 !important;
  margin: 0 !important;
}



.Cal__Day__root {
  font-size: 1 rem !important;
} */

.Cal__Header__root {
  padding: 25px !important;
  /* background-color: #f89639 !important; */
}

.Cal__Weekdays__root {
  /* background-color: #ffaf64 !important; */
}

/* @media screen and (max-width: 768px) {
  .Cal__Header__root {
    padding: 25px !important;
  }
} */

.date-picker-shadow {
  box-shadow: 0px 15px 10px -15px #111;
}

@media only screen and (max-width: 600px) {
  .mob-text {
    text-align: center;
  }
  .width-75 {
    width: 100% !important;
  }
  .booktext {
    font-size: 1.6rem !important;
  }
}

.width-75 {
  width: 80%;
}

.booktext {
  font-size: 2.3rem;
}

.link_hover:hover {
  color: #a3762c;
  text-decoration: none;
}

.book_link_hover:hover {
  color: #fff;
  text-decoration: none;
}

.text_white_more {
  font-weight: bold;
  font-size: 48px;
  color: #a02b2d;
  -webkit-text-stroke: 1px white;
}

.bottom-navigation {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-evenly;
  background-color: #8f301d;
  padding: 5px 20px 0px 20px;
  z-index: 1000;
}

.bottom_navigator_label {
  display: inline;
  color: white;
}

@media (max-width: 1000px) {
  .bottom_navigator_label {
    display: none;
    color: white;
  }
}

.bottom_navigator_mobile_label {
  display: none;
  color: white;
}

@media (max-width: 1000px) {
  .bottom_navigator_mobile_label {
    display: inline;
    color: white;
  }
}

.bottom-navigation-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 5px;
}

.bn-active {
  color: #f89620;
}

/**  top navigation*/
.navigation {
  background-color: #ffffff;
  height: 80px;
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.0975);
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 0px 50px;
  box-sizing: border-box;
  z-index: 2;
  /* animation magic */
  transition: all 0.4s ease-in-out;
  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
}

.shrink {
  height: 50px;
}

.navigation .logo a {
  position: relative;
  top: -6px;
  color: #000000;
  font-size: 50px;
  font-family: "Julies", sans-serif;
  text-decoration: none;
}

.navigation-search-container {
  position: relative;
}

.navigation-search-container input {
  background-color: #fafafa;
  padding: 3px 20px;
  padding-left: 25px;
  height: 30px;
  box-sizing: border-box;
  border: 1px solid rgba(0, 0, 0, 0.0975);
  border-radius: 3px;
  font-size: 14px;
}

.navigation-search-container .fa-search {
  position: absolute;
  top: 10px;
  left: 10px;
  font-size: 11px;
  color: rgba(0, 0, 0, 0.5);
}

@media only screen and (min-width: 320px) and (max-width: 650px) {
  /* Navigation */
  .navigation {
    padding: 0 20px;
    justify-content: space-between;
  }
  .navigation-search-container {
    display: none;
  }
  .navigation-icons {
    display: flex;
  }
}

.navigation-icons {
  display: flex;
}

.navigation-search-container input:focus {
  outline: none;
}

.navigation-search-container input::-webkit-input-placeholder {
  text-align: center;
}

.navigation-search-container input:-ms-input-placeholder {
  text-align: center;
}

.navigation-search-container input::-ms-input-placeholder {
  text-align: center;
}

.navigation-search-container input::placeholder {
  text-align: center;
}

.navigation-icons a {
  text-decoration: none;
}

.navigation-link i {
  margin-left: 30px;
  color: black;
  text-decoration: none;
  font-size: 22px;
}

.notification-bubble-wrapper {
  position: relative;
  top: -30px;
  left: 17px;
}

.notification-bubble {
  position: absolute;
  min-width: 20px;
  min-height: 20px;
  border-radius: 50%;
  background: #ff2c74;
  color: #fff;
  text-align: center;
  font-size: 13px;
  padding: 2px 5px 3px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-weight: 500;
  display: none;
}

/* end header */

