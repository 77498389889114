@import url("https://fonts.googleapis.com/css?family=Nunito&display=swap");

body {
  margin: 0;
  font-family: "Nunito", "Muli", sans-serif;
}

.banner-image {
  /* background-image: url("/venningtonbanner.png"); */
  background-image: linear-gradient(
      0deg,
      rgba(29, 15, 23, 0.3),
      rgba(14, 5, 10, 0.3)
    ),
    url("https://res.cloudinary.com/relive-cloud/image/upload/v1591695975/vennington/lobby-area.jpg");
  background-repeat: no-repeat;
  margin: 0px;
  padding: 0px;
  height: 80vh;
  width: 100%;
  background-size: cover;
  background-position: center center;
}

.bg-orange {
  background-color: #f89620;
}
.rounded {
  border-radius: 30px !important;
}

.roomimgwidth {
  width: 100%;
  height: 70%;
}

.bg-grey {
  background: #e3e3e3;
  border-radius: 50px;
  padding: 10px;
}
.text-orange {
  color: #f89639;
}

.roomimgwidth:hover {
  -webkit-box-shadow: 0px 0px 13px 9px rgba(0, 0, 0, 0.07);
  -moz-box-shadow: 0px 0px 13px 9px rgba(0, 0, 0, 0.07);
  box-shadow: 0px 0px 13px 9px rgba(0, 0, 0, 0.07);

  cursor: pointer;
}

.roomdetailsimg {
  width: 100%;
  height: 70vh;
  /* background-size: cover; */
  background-repeat: no-repeat;
  background-position: center;
  z-index: -999;
}
.roomdetails {
  border-top-left-radius: 40px;
  border-top-right-radius: 40px;
  margin-top: -50px;
  margin-bottom: -47.5px;
}

.de-active {
  cursor: pointer;
  padding: 10px;
  margin: 0;
}

.active {
  cursor: pointer;
  padding: 10px;
  margin: 0;
  font-size: 1.2rem;
}

@media only screen and (max-width: 500px) {
  .h6 {
    font-size: 12px !important ;
  }
  .w-55 {
    width: 100% !important;
  }
}

.btn-round {
  background: #a02b2d;
  border-radius: 50px;
  color: #fff;
}

.w-55 {
  width: 55%;
}

.aminitext {
  font-size: 14px;
}

.clubsimg {
  width: 100%;
  height: 250px;
}

.inquirybtn {
  border-radius: 50px;
}

.textwrap {
  white-space: nowrap;
}

a {
  color: inherit;
}

.facilitiesicon {
  width: 60px;
}

.bordermin {
  border: 5px solid #a3762c;
  border-radius: 50px;
  width: 10%;
}

.borderminser {
  border: 5px solid #a3762c;
  border-radius: 50px;
  width: 5%;
}

.serviceicon {
  width: 100px;
}

.facilitiespageicon {
  width: 400px;
}

.mycontent-left {
  border-left: 1px solid #e3e3e3;
}

.formw {
  width: 72% !important;
}

.text-maroon {
  color: #8f301d;
}

.roomtext {
  color: #6d3258;
}

.headtext {
  color: #a3762c !important;
}

a:hover {
  color: none;
  border-bottom: 2px solid #a3762c;
}

.text-dg {
  color: #545959;
}

.btn-outline-maroon {
  border: 1px solid #8f301d;
  color: #8f301d;
}

.btn-outline-maroon {
  background: #8f301d;
  color: #fff;
}
.text-lightgolden {
  color: #867f74;
}

.bg-golden {
  background: #a3762c;
  color: #fff;
}

.bg-cover {
  height: 80vh;
  background-repeat: no-repeat;
  background-size: cover;
}

.btn-dark {
  background: #000 !important;
}

@media (max-width: 500) {
  .mobw {
    text-align: center;
  }
  .text-right {
    display: flex !important;
    justify-content: center !important;
  }
}
