.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* .Cal__Header__date {
  font-size: 1.5rem !important;
  padding: 0 !important;
  margin: 0 !important;
}



.Cal__Day__root {
  font-size: 1 rem !important;
} */

.Cal__Header__root {
  padding: 25px !important;
  /* background-color: #f89639 !important; */
}

.Cal__Weekdays__root {
  /* background-color: #ffaf64 !important; */
}

/* @media screen and (max-width: 768px) {
  .Cal__Header__root {
    padding: 25px !important;
  }
} */

.date-picker-shadow {
  box-shadow: 0px 15px 10px -15px #111;
}

@media only screen and (max-width: 600px) {
  .mob-text {
    text-align: center;
  }
  .width-75 {
    width: 100% !important;
  }
  .booktext {
    font-size: 1.6rem !important;
  }
}

.width-75 {
  width: 80%;
}

.booktext {
  font-size: 2.3rem;
}

.link_hover:hover {
  color: #a3762c;
  text-decoration: none;
}

.book_link_hover:hover {
  color: #fff;
  text-decoration: none;
}

.text_white_more {
  font-weight: bold;
  font-size: 48px;
  color: #a02b2d;
  -webkit-text-stroke: 1px white;
}
