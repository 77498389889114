.bottom-navigation {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-evenly;
  background-color: #8f301d;
  padding: 5px 20px 0px 20px;
  z-index: 1000;
}

.bottom_navigator_label {
  display: inline;
  color: white;
}

@media (max-width: 1000px) {
  .bottom_navigator_label {
    display: none;
    color: white;
  }
}

.bottom_navigator_mobile_label {
  display: none;
  color: white;
}

@media (max-width: 1000px) {
  .bottom_navigator_mobile_label {
    display: inline;
    color: white;
  }
}

.bottom-navigation-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 5px;
}

.bn-active {
  color: #f89620;
}

/**  top navigation*/
.navigation {
  background-color: #ffffff;
  height: 80px;
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.0975);
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 0px 50px;
  box-sizing: border-box;
  z-index: 2;
  /* animation magic */
  transition: all 0.4s ease-in-out;
  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
}

.shrink {
  height: 50px;
}

.navigation .logo a {
  position: relative;
  top: -6px;
  color: #000000;
  font-size: 50px;
  font-family: "Julies", sans-serif;
  text-decoration: none;
}

.navigation-search-container {
  position: relative;
}

.navigation-search-container input {
  background-color: #fafafa;
  padding: 3px 20px;
  padding-left: 25px;
  height: 30px;
  box-sizing: border-box;
  border: 1px solid rgba(0, 0, 0, 0.0975);
  border-radius: 3px;
  font-size: 14px;
}

.navigation-search-container .fa-search {
  position: absolute;
  top: 10px;
  left: 10px;
  font-size: 11px;
  color: rgba(0, 0, 0, 0.5);
}

@media only screen and (min-width: 320px) and (max-width: 650px) {
  /* Navigation */
  .navigation {
    padding: 0 20px;
    justify-content: space-between;
  }
  .navigation-search-container {
    display: none;
  }
  .navigation-icons {
    display: flex;
  }
}

.navigation-icons {
  display: flex;
}

.navigation-search-container input:focus {
  outline: none;
}

.navigation-search-container input::placeholder {
  text-align: center;
}

.navigation-icons a {
  text-decoration: none;
}

.navigation-link i {
  margin-left: 30px;
  color: black;
  text-decoration: none;
  font-size: 22px;
}

.notification-bubble-wrapper {
  position: relative;
  top: -30px;
  left: 17px;
}

.notification-bubble {
  position: absolute;
  min-width: 20px;
  min-height: 20px;
  border-radius: 50%;
  background: #ff2c74;
  color: #fff;
  text-align: center;
  font-size: 13px;
  padding: 2px 5px 3px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-weight: 500;
  display: none;
}

/* end header */
